import { create } from "zustand";
import { User } from "../graphql/generated/schema";
import { logger } from "./logger";

export interface IUserAuthStore {
  user: User | null;
}
const initialUserStore: IUserAuthStore = {
  user: null,
};

export const useUserStore = create<IUserAuthStore>()(
  logger((set) => initialUserStore, "user-store")
);
