import { CircularProgress, Typography } from "@mui/material";
import styles from "./loading.module.scss";
import { ILoading } from "./types";

const Loading = (props: ILoading) => {
  const { isLoading, loaderStyles, loaderText } = props;

  if (!isLoading) return <></>;

  return (
    <div className={styles.loading} style={loaderStyles}>
      <CircularProgress />
      {loaderText && (
        <Typography mt="4px" variant="bodyLargeBold">
          {loaderText}
        </Typography>
      )}
    </div>
  );
};

export default Loading;
