import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { DRAWER_WIDTH } from "./index.constants";

export const StyledDrawer = styled(Drawer)(() => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  boxShadow: "0px 2px 4px 0px rgba(165, 163, 174, 0.30)",
  "& .MuiDrawer-paper": {
    width: DRAWER_WIDTH,
    boxSizing: "border-box",
    backgroundColor: "#fff",
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(1.75),
  marginRight: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "10px 16px",
  gap: theme.spacing(1.25),
  borderRadius: "6px 200px 200px 6px",
  "&:hover": {
    backgroundColor: theme.palette.secondary[100],
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiListItemIcon-root": {
      filter:
        "invert(27%) sepia(112%) saturate(6603%) hue-rotate(263deg) brightness(88%) contrast(93%)",
    },
  },
}));

export const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    "& .MuiBottomNavigationAction-label": {
      whiteSpace: "nowrap",
      color: theme.palette.secondary.main,
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
    },
    "&.Mui-selected": {
      filter:
        "invert(27%) sepia(112%) saturate(6603%) hue-rotate(263deg) brightness(88%) contrast(93%)",
      "& .MuiBottomNavigationAction-label": {
        color: theme.palette.primary.main,
        fontSize: "10px",
        fontWeight: 700,
      },
    },
  })
);
