import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {
  ActiveNavigationTabOptionsEnum,
  useLayoutStore,
} from "../../../store/layout.store";
import { StyledDrawer, StyledList } from "../index.styled";
import SideBarList from "./SideBarList";
import { ISidebar } from "./types";

const SideBar = (props: ISidebar) => {
  const { handleLogout } = props;
  const navigate = useNavigate();

  const activeNavigationTab = useLayoutStore(
    (state) => state.activeNavigationTab
  );

  const setActiveNavigationTab = useLayoutStore(
    (state) => state.setActiveNavigationTab
  );

  const handleSideBarListItemClick = (path: ActiveNavigationTabOptionsEnum) => {
    setActiveNavigationTab(path);
    navigate(`/${path}`);
  };

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Box m="24px 48px 39px 32px">
        <Box component="img" src="/Images/LOGO.png" width={152} />
      </Box>
      <StyledList>
        <SideBarList
          image="MyStore"
          text="My Store"
          handleClick={() =>
            handleSideBarListItemClick(ActiveNavigationTabOptionsEnum.MY_STORE)
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.MY_STORE
          }
        />
        <SideBarList
          image="Income"
          text="Income"
          handleClick={() =>
            handleSideBarListItemClick(ActiveNavigationTabOptionsEnum.INCOME)
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.INCOME
          }
        />
        <SideBarList
          image="Analytics"
          text="Analytics"
          handleClick={() =>
            handleSideBarListItemClick(ActiveNavigationTabOptionsEnum.ANALYTICS)
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.ANALYTICS
          }
        />
        <SideBarList
          image="Audience"
          text="Audience"
          handleClick={() =>
            handleSideBarListItemClick(ActiveNavigationTabOptionsEnum.AUDIENCE)
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.AUDIENCE
          }
        />
        <SideBarList
          image="clock"
          text="Appointments"
          handleClick={() =>
            handleSideBarListItemClick(
              ActiveNavigationTabOptionsEnum.APPOINTMENTS
            )
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.APPOINTMENTS
          }
        />
        <SideBarList
          image="Referrals"
          text="Referrals"
          handleClick={() =>
            handleSideBarListItemClick(ActiveNavigationTabOptionsEnum.REFERRALS)
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.REFERRALS
          }
        />
        <SideBarList
          image="eye2"
          text="Preview"
          handleClick={() =>
            handleSideBarListItemClick(ActiveNavigationTabOptionsEnum.PREVIEW)
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.PREVIEW
          }
        />
      </StyledList>
      <StyledList sx={{ mt: "auto" }}>
        <SideBarList
          image="setting"
          text="Settings"
          handleClick={() =>
            handleSideBarListItemClick(ActiveNavigationTabOptionsEnum.SETTINGS)
          }
          isActive={
            activeNavigationTab === ActiveNavigationTabOptionsEnum.SETTINGS
          }
        />
        <SideBarList
          image="logout"
          text="Sign Out"
          handleClick={handleLogout}
          isActive={false}
        />
      </StyledList>
    </StyledDrawer>
  );
};

export default SideBar;
