import { InputAdornment, Box, Typography } from "@mui/material";

const InputTextAdornment = ({
  imageSrc,
  text,
}: {
  imageSrc: string;
  text?: string;
}) => (
  <InputAdornment position="start" sx={{ mr: "0!important" }}>
    <Box display="flex" gap={1}>
      <Box
        component="img"
        src={imageSrc}
        alt="Adornment"
        width="20px"
        height="20px"
      />
      <Typography variant="body1" fontWeight={800} color="secondary.main">
        {text}
      </Typography>
    </Box>
  </InputAdornment>
);

export default InputTextAdornment;
