import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useGetMediaQueryUp } from "../../hooks/useGetMediaQuery";

const SignupTitle = ({
  title,
  subTitle,
  logo = true,
  source = "SIGNUP",
}: {
  title: string;
  subTitle?: string;
  logo?: boolean;
  source?: "ONBOARDING" | "SIGNUP";
}) => {
  const isSmUp = useGetMediaQueryUp("sm");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
      }}
    >
      {logo && (
        <Box
          component={"img"}
          src={"/Images/LOGO.png"}
          height={"auto"}
          width={184}
        />
      )}
      <Box>
        <Typography
          sx={{ whiteSpace: { xs: "none", sm: "nowrap" } }}
          variant={isSmUp ? (source === "ONBOARDING" ? "h4" : "h3") : "h5"}
          color="secondary.main"
        >
          {title}
        </Typography>
        <Box
          component={"img"}
          src={"/Images/SignUp/LINE_VECTOR.png"}
          width={182}
          height="auto"
          sx={{
            display: "flex",
            ml: "auto",
            pt: source === "ONBOARDING" ? 0 : 1.25,
          }}
        />
      </Box>
      {subTitle && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: 406,
            width: "100%",
          }}
        >
          <Typography
            variant="subtitle"
            color="secondary.700"
            textAlign="center"
          >
            {subTitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SignupTitle;
