import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/common.constants";
import { useUpdateUserProfileMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";

const InterestedInBrandDeals = () => {
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const [InterestedInBrandDeals, setInterestedInBrandDeals] = useState(false);

  const setAuthState = useAuthStore.setState;

  const [interestedInBrandDeals, { error }] = useUpdateUserProfileMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        onboardingStepFinished: [
          ...state.onboardingStepFinished,
          "ADD_BRAND_DEAL",
        ],
        onboardingStatus: "completed",
        onboardingActiveStep: 0,
      }));

      navigate(ROUTES.ONBOARDING_SUCCESS);
    },
    variables: {
      input: {
        brandDeals: InterestedInBrandDeals,
        addToUserOnBoardingStepFinished: "ADD_BRAND_DEAL",
        userOnBoardingStatus: "completed",
      },
    },
  });

  const handleButtonClick = (value: boolean) => {
    setInterestedInBrandDeals(value);
    interestedInBrandDeals();
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            handleButtonClick(true);
          }}
          sx={{ borderRadius: "50px" }}
        >
          Yes
        </Button>
        <Button
          sx={{ borderRadius: "50px" }}
          onClick={() => {
            handleButtonClick(false);
          }}
        >
          No
        </Button>
      </Box>
    </>
  );
};

export default InterestedInBrandDeals;
