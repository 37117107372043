import _ from "lodash";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import {
  User,
  useGetUserDetailLazyQuery,
} from "../../graphql/generated/schema";
import MainLayout from "../../layouts/MainLayout";
import { useUserStore } from "../../store/user.store";
import { isUserLoggedIn } from "../../utils/auth";
import { GuardProps } from "./types";

const AuthGuard = (props: GuardProps) => {
  const { noLayout } = props;
  const setUserStore = useUserStore.setState;
  const user = useUserStore((state) => state.user);

  const [getUserDetails, { loading }] = useGetUserDetailLazyQuery({
    onCompleted(data) {
      setUserStore({ user: data.getUserDetail.data as User });
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    if (!isLoggedIn) {
      const refPath = window.location.pathname + window.location.search;
      navigate(`/signin?ref=${refPath}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn && _.isEmpty(user)) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  if (loading)
    return (
      <Loading isLoading loaderStyles={{ height: "100vh", width: "100vw" }} />
    );

  if (noLayout) return <Outlet />;
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export default AuthGuard;
