import { create } from "zustand";
import { logger } from "./logger";

export enum ActiveNavigationTabOptionsEnum {
  MY_STORE = "my-store",
  INCOME = "income",
  ANALYTICS = "analytics",
  AUDIENCE = "audience",
  APPOINTMENTS = "appointments",
  REFERRALS = "referrals",
  SETTINGS = "settings",
  PREVIEW = "preview",
  MENU = "menu",
}

export interface ILayoutStore {
  activeNavigationTab: ActiveNavigationTabOptionsEnum;
  setActiveNavigationTab: (activeTab: ActiveNavigationTabOptionsEnum) => void;
}

export const useLayoutStore = create<ILayoutStore>()(
  logger(
    (set) => ({
      activeNavigationTab: Object.values(ActiveNavigationTabOptionsEnum).some(
        (e) => e === window.location.pathname.split("/")[1]
      )
        ? (window.location.pathname.split(
            "/"
          )[1] as ActiveNavigationTabOptionsEnum)
        : ActiveNavigationTabOptionsEnum.MY_STORE,

      setActiveNavigationTab: (activeTab: ActiveNavigationTabOptionsEnum) => {
        set({ activeNavigationTab: activeTab });
      },
    }),
    "layout-store"
  )
);
