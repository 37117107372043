import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ConnectYourSocials from "../../../components/OnBoarding/ConnectYourSocials";
import DigitalProduct from "../../../components/OnBoarding/DigitalProduct";
import InterestedInBrandDeals from "../../../components/OnBoarding/InterestedInBrandDeals";
import LetsWriteABio from "../../../components/OnBoarding/LetsWriteABio";
import PickYourNiche from "../../../components/OnBoarding/PickYourNiche";
import SomeOneRefered from "../../../components/OnBoarding/SomeOneRefered";
import YourMainGoal from "../../../components/OnBoarding/YourMainGoal";
import SignupTitle from "../../../components/SignUp/SignupTitle";
import { ROUTES } from "../../../constants/common.constants";
import { ONBOARDDING_STEP } from "../../../constants/onboarding.constants";
import { useGetUserDetailLazyQuery } from "../../../graphql/generated/schema";
import { useAuthStore } from "../../../store/auth.store";
import OnBoardingPageSkeleton from "./OnBoardingPageSkeleton";

const OnBoarding = () => {
  const [
    getUserDetails,
    { data: userDetailsResponse, loading: userDetailsLoading },
  ] = useGetUserDetailLazyQuery();

  const setAuthState = useAuthStore.setState;
  const activeStep = useAuthStore((state) => state.onboardingActiveStep);
  const emailVerified = useAuthStore((state) => state.emailVerified);
  const navigate = useNavigate();

  useEffect(() => {
    //on page load if onboarding step in store=0 then only call getUserDetails and check active step with backend, as application storage might have been cleared by user
    if (activeStep === 0) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_.isEmpty(userDetailsResponse?.getUserDetail?.data)) return;

    const onboardingStepsFinished =
      userDetailsResponse?.getUserDetail.data.userOnboarding?.stepFinished ??
      [];

    if (!userDetailsResponse?.getUserDetail.data.emailVerified) {
      navigate(ROUTES.SIGNUP, { replace: true });
    }

    if (activeStep !== onboardingStepsFinished.length) {
      setAuthState({
        onboardingActiveStep: onboardingStepsFinished.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsResponse]);

  useEffect(() => {
    if (!emailVerified) {
      navigate(ROUTES.SIGNUP, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerified]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          maxWidth: 672,
          width: "100%",
        }}
      >
        {userDetailsLoading ? (
          <OnBoardingPageSkeleton />
        ) : (
          <>
            <Typography
              color="primary.200"
              fontWeight={600}
              lineHeight={"normal"}
              variant="body1"
              textAlign="center"
              pb={1.5}
            >
              {activeStep === 6 ? "Final step" : `Step ${activeStep + 1}/7`}
            </Typography>
            <SignupTitle
              logo={false}
              source="ONBOARDING"
              title={ONBOARDDING_STEP[activeStep].title}
              subTitle={ONBOARDDING_STEP[activeStep].subTitle}
            />

            {activeStep === 0 && <ConnectYourSocials />}
            {activeStep === 1 && <DigitalProduct />}
            {activeStep === 2 && <YourMainGoal />}
            {activeStep === 3 && <PickYourNiche />}
            {activeStep === 4 && <LetsWriteABio />}
            {activeStep === 5 && <SomeOneRefered />}
            {activeStep === 6 && <InterestedInBrandDeals />}
          </>
        )}
      </Box>
    </>
  );
};

export default OnBoarding;
