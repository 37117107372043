import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledSignupInputText = styled(TextField)(({ theme }) => ({
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary[800],
    },
  },
  "& .MuiFormHelperText-root": { paddingTop: 12, textAlign: "center" },
  "& .MuiFormControl-root": { border: "none" },
  "& .MuiOutlinedInput-notchedOutline": {
    ":after": {
      borderBottom: "none",
    },
    ":before": {
      borderBottom: "none",
    },
    border: "2px solid",
    borderColor: theme.palette.neutral[800],
  },
  "& .MuiTypography-root": {
    alignSelf: "center",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
  },
  "& .MuiInputBase-root": {
    borderRadius: "50px",
    padding: "10px 16px",
    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
  "&.MuiInput-underline": {
    ":after": {
      borderBottom: "none",
    },
    ":before": {
      borderBottom: "none",
    },
  },
}));

export default StyledSignupInputText;
