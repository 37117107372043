import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../../constants/common.constants";
import SignInLayout from "../../layouts/SignInLayout";
import { isUserLoggedIn } from "../../utils/auth";
import { GuardProps } from "./types";

const GuestGuard = (props: GuardProps) => {
  const { noLayout } = props;

  if (isUserLoggedIn()) {
    return <Navigate to={ROUTES.MY_STORE} />;
  }

  if (noLayout) return <Outlet />;
  return (
    <SignInLayout>
      <Outlet />
    </SignInLayout>
  );
};

export default GuestGuard;
