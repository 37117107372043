import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";
import { CastedForwardRefButtonType } from "./types";
import { LoadingButton } from "@mui/lab";

const UIStyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: "none",
  borderRadius: theme.spacing(6.25),
  fontSize: "16px",
  fontWeight: 700,
  "&.MuiButton-sizeSmall": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
}));

export const UIStyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: theme.spacing(6.25),
  fontSize: "16px",
  fontWeight: 700,
  "&.MuiButton-sizeSmall": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
}));

const CastedForwardRefButtonFnc: CastedForwardRefButtonType = (props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...rest } = props;

  return (
    <UIStyledButton ref={ref} {...rest}>
      {children}
    </UIStyledButton>
  );
};

const CastedForwardRefLoadingButtonFnc: CastedForwardRefButtonType = (
  props,
  ref
) => {
  // eslint-disable-next-line react/prop-types
  const { children, ...rest } = props;

  return (
    <UIStyledLoadingButton ref={ref} {...rest}>
      {children}
    </UIStyledLoadingButton>
  );
};

export const StyledButton = forwardRef(
  CastedForwardRefButtonFnc
) as CastedForwardRefButtonType;

export const StyledLoadingButton = forwardRef(
  CastedForwardRefLoadingButtonFnc
) as CastedForwardRefButtonType;
