import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/common.constants";
import { useVerifyRegisteredEmailMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";

const EmailVerification = () => {
  const setAuthState = useAuthStore.setState;
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [alert, setAlert] = useState(false);

  const handleChangeOtp = (values: string) => {
    setOtp(values);
  };

  const [verifyOTP, { loading, error }] = useVerifyRegisteredEmailMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState({
        emailVerified: true,
        signUpActiveStep: 0,
      });
      navigate(ROUTES.ONBOARDING);
    },

    variables: {
      input: {
        verificationCode: otp,
      },
    },
  });

  const handleSubmitOtp = () => {
    verifyOTP();
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        {alert && <Alert severity="error">{error?.message}</Alert>}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
          "& > div": {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "228px",
            width: "100%",
          },
        }}
      >
        <OtpInput
          inputStyle={{
            width: "48px",
            borderRadius: "6px",
            border: "1.5px solid #E5E7EB",
            padding: "16px 12px",
            fontSize: "24px",
            color: "#9CA3AF",
          }}
          placeholder="0000"
          value={otp}
          onChange={handleChangeOtp}
          numInputs={4}
          renderInput={(props) => <input name="otp" {...props} />}
        />
        <LoadingButton loading={loading} variant="contained" sx={{ borderRadius: "50px", width: "100%" }} onClick={handleSubmitOtp}>
          Verify Email
        </LoadingButton>
      </Box>
    </>
  );
};

export default EmailVerification;
