import { create } from "zustand";
import { persist } from "zustand/middleware";
import { logger } from "./logger";

export type OnboardingStepsType = Array<
  | "ADD_SOCIAL"
  | "ADD_DIGITAL_PRODUCT"
  | "ADD_GOAL"
  | "ADD_NICHE"
  | "ADD_BIO"
  | "ADD_REFER"
  | "ADD_BRAND_DEAL"
>;

export type UserOnboardingStatus = "inProgress" | "completed";
export interface IAuthStore {
  userName: string;
  fullName: string;
  email: string;
  emailVerified: boolean;
  token: string;
  onboardingStatus: UserOnboardingStatus;
  onboardingStepFinished: OnboardingStepsType;
  signUpActiveStep: number;
  onboardingActiveStep: number;
}

export const initialAuthStore: IAuthStore = {
  userName: "",
  fullName: "",
  email: "",
  emailVerified: false,
  token: "",
  onboardingStatus: "inProgress",
  onboardingStepFinished: [],
  signUpActiveStep: 0,
  onboardingActiveStep: 0,
};

export const useAuthStore = create<IAuthStore>()(
  logger(
    persist((set) => initialAuthStore, {
      name: "auth-store",
    }),
    "auth-store"
  )
);
