import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import CryptoJS from "crypto-js";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useVerifyForgetPasswordOtpMutation } from "../../graphql/generated/schema";

const ForgetPasswordEmailVerification = ({ email }: { email: string }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [alert, setAlert] = useState(false);
  const [invalidOtp, _] = useState(false);

  const handleChangeOtp = (values: string) => {
    setOtp(values);
  };

  const [verifyOTP, { loading, error }] = useVerifyForgetPasswordOtpMutation({
    onError: () => {
      setAlert(false);
    },
    onCompleted: () => {
      navigate(
        `/reset-password?email=${CryptoJS.AES.encrypt(
          email,
          process.env.REACT_APP_ENCRYPTION_KEY as string
        ).toString()}`
      );
    },
    variables: {
      input: {
        email: email,
        forgetPasswordCode: otp,
      },
    },
  });

  const handleSubmitOtp = () => {
    verifyOTP();
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
          "& div": {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "228px",
            width: "100%",
          },
        }}
      >
        <OtpInput
          inputStyle={{
            width: "48px",
            borderRadius: "6px",
            border: "1.5px solid #E5E7EB",
            padding: "16px 12px",
          }}
          placeholder="0000"
          value={otp}
          onChange={handleChangeOtp}
          numInputs={4}
          renderInput={(props) => <input name="otp" {...props} />}
        />
        {invalidOtp && (
          <FormHelperText error sx={{ ml: 2 }}>
            {"Enter valid OTP"}
          </FormHelperText>
        )}
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ borderRadius: "50px", width: "100%" }}
          onClick={handleSubmitOtp}
        >
          Verify
        </LoadingButton>
      </Box>
    </>
  );
};

export default ForgetPasswordEmailVerification;
