import { Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { ROUTES } from "../../constants/common.constants";
import { useResendVerificationCodeMutation } from "../../graphql/generated/schema";
const AuthLinks = ({
  source = "SIGNUP",
  activeStep,
}: {
  source?: "SIGNIN" | "SIGNUP";
  activeStep?: number;
}) => {
  const [resendVerificationCode, { loading: resendVerificationCodeLoading }] =
    useResendVerificationCodeMutation({
      onCompleted: () => {
        toast.success("Verification code sent to your email!");
      },
      onError: () => {
        toast.error("Error sending verification code!");
      },
    });
  const handleResendVerificationCode = async () => {
    await resendVerificationCode();
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="center" gap={0.5}>
        <Typography variant="body1" align="center">
          {source === "SIGNUP"
            ? activeStep === 2
              ? "Remember Your Password?"
              : "Already have an account?"
            : "Don’t have an account?"}
        </Typography>
        <Box
          component={"a"}
          href={source === "SIGNIN" ? ROUTES.SIGNUP : ROUTES.SIGNIN}
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body1" align="center" color="primary.main">
            {source === "SIGNUP" ? "Sign In" : "Sign Up"}
          </Typography>
        </Box>
      </Box>
      {activeStep === 2 && (
        <Button variant="text" onClick={handleResendVerificationCode}>
          {resendVerificationCodeLoading ? (
            <CircularProgress size={30} />
          ) : (
            "Resend verification code"
          )}
        </Button>
      )}
      <Box display="flex" justifyContent="center" gap={0.5} flexWrap="wrap">
        <Typography variant="body1" align="center">
          By continuing, you agree to our
        </Typography>
        <Box component={"a"} href="/terms" sx={{ textDecoration: "none" }}>
          <Typography variant="body1" align="center" color="primary.main">
            Terms of Use
          </Typography>
        </Box>
        and
        <Box component={"a"} href="/privacy" sx={{ textDecoration: "none" }}>
          <Typography variant="body1" align="center" color="primary.main">
            Privacy Policy.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLinks;
