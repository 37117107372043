import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CryptoJS from "crypto-js";
import { useFormik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import SignupTitle from "../../../components/SignUp/SignupTitle";
import StyledInputText from "../../../components/UiComponents/InputText";
import InputTextAdornment from "../../../components/common/InputTextAdornment";
import { ROUTES } from "../../../constants/common.constants";
import { useResetPasswordMutation } from "../../../graphql/generated/schema";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleDecrypt = () => {
    const decrypted = CryptoJS.AES.decrypt(
      search.split("email=")[1],
      process.env.REACT_APP_ENCRYPTION_KEY as string
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const [alert, setAlert] = useState(false);
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("New Password is required.")
      .min(6, "Password must be at least 8 characters"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required.")
      .oneOf([yup.ref("password"), ""], " New Password does not match."),
  });

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: validationSchema,
      onSubmit: async () => {
        resetPassword();
      },
    });

  const [resetPassword, { loading, error }] = useResetPasswordMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      navigate(ROUTES.SIGNIN);
    },
    variables: {
      input: {
        email: handleDecrypt(),
        password: values.password,
      },
    },
  });

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}

      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}
      >
        <SignupTitle
          title={"Reset Password!?"}
          subTitle={
            "Your new password must be different from previously used password."
          }
        />
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 4 }}
        >
          <StyledInputText
            name="password"
            placeholder="Enter New Password"
            value={values.password}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            onChange={handleChange}
            type="password"
            InputProps={{
              startAdornment: (
                <InputTextAdornment imageSrc={"/Images/Icons/LOCK.svg"} />
              ),
            }}
          />
          <StyledInputText
            name="confirmPassword"
            placeholder="Confirm New Password"
            value={values.confirmPassword}
            onBlur={handleBlur}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            onChange={handleChange}
            type="password"
            InputProps={{
              startAdornment: (
                <InputTextAdornment imageSrc={"/Images/Icons/LOCK.svg"} />
              ),
            }}
          />
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ borderRadius: "50px" }}
            loading={loading}
          >
            Continue
          </LoadingButton>
          <Box display="flex" justifyContent="center" gap={0.5} flexWrap="wrap">
            <Typography variant="body1" align="center">
              By continuing, you agree to our
            </Typography>
            <Box component={"a"} href="/terms" sx={{ textDecoration: "none" }}>
              <Typography variant="body1" align="center" color="primary.main">
                Terms of Use
              </Typography>
            </Box>
            and
            <Box
              component={"a"}
              href="/privacy"
              sx={{ textDecoration: "none" }}
            >
              <Typography variant="body1" align="center" color="primary.main">
                Privacy Policy.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
