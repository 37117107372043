import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormik } from "formik";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import * as yup from "yup";
import { useCreateUserMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";
import StyledInputText from "../UiComponents/InputText";
import InputTextAdornment from "../common/InputTextAdornment";
import { SignUpStepsPropsType } from "./ClaimYourLink";

export type PersonalDetails = {
  userName: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  password: string;
};

const PersonalDetails = ({ userName }: SignUpStepsPropsType) => {
  const [alert, setAlert] = useState(false);
  const setAuthState = useAuthStore.setState;

  const validationSchema = yup.object({
    userName: yup.string().required("User name is required"),
    fullName: yup.string().required("Full name is required"),
    phoneNumber: yup.string().required("Phone number is required"),
    email: yup
      .string()
      .email("Enter valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password should contain minimum 6 characters"),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      userName: userName,
      fullName: "",
      phoneNumber: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      createUser();
    },
  });

  const [createUser, { loading, error }] = useCreateUserMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: (data) => {
      const userData = data.createUser;

      setAuthState((state) => ({
        email: userData.email,
        token: userData.token,
        userName: userData.userName,
        signUpActiveStep: state.signUpActiveStep + 1,
        fullName: userData.fullName,
      }));
    },

    variables: {
      input: {
        userName: values.userName,
        email: values.email,
        fullName: values.fullName,
        password: values.password,
        phoneNumber: values.phoneNumber,
      },
    },
  });

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      >
        <StyledInputText
          name="userName"
          placeholder="User Name"
          value={values.userName}
          disabled
          // onChange={handleChange}
          // onBlur={handleBlur}
          error={touched.userName && Boolean(errors.userName)}
          helperText={touched.userName && errors.userName}
          InputProps={{
            startAdornment: (
              <InputTextAdornment
                imageSrc={"/Images/FEVICON.png"}
                text={"supr.club/"}
              />
            ),
          }}
          sx={{
            "& input:disabled": { "-webkit-text-fill-color": "#000" },
            "& input:disabled ~ fieldset": {
              borderColor: "#EAECF0 !important",
            },
          }}
        />
        <StyledInputText
          name="fullName"
          placeholder="Enter Your Full Name"
          value={values.fullName}
          onBlur={handleBlur}
          error={touched.fullName && Boolean(errors.fullName)}
          helperText={touched.fullName && errors.fullName}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputTextAdornment imageSrc={"/Images/Icons/USER.svg"} />
            ),
          }}
        />
        <StyledInputText
          name="email"
          placeholder="Enter Your Email ID"
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          value={values.email}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputTextAdornment imageSrc={"/Images/Icons/MAIL.svg"} />
            ),
          }}
        />
        <FormControl>
          <Box sx={{ position: "absolute", zIndex: 5, top: 11, left: 16 }}>
            <Box component="img" src="/Images/Icons/PHONE.svg" />
          </Box>

          <PhoneInput
            inputProps={{
              name: "phoneNumber",
            }}
            specialLabel=""
            placeholder="+91 - 0000-000-000"
            country={"in"}
            value={values.phoneNumber}
            onChange={(value) => setFieldValue("phoneNumber", value as string)}
            onBlur={handleBlur}
            inputClass={
              touched.phoneNumber && errors.phoneNumber ? "error" : ""
            }
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <FormHelperText error sx={{ pt: 1, textAlign: "center" }}>
              {errors.phoneNumber}
            </FormHelperText>
          )}
        </FormControl>
        <StyledInputText
          name="password"
          placeholder="Set Password"
          value={values.password}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          onChange={handleChange}
          type="password"
          InputProps={{
            startAdornment: (
              <InputTextAdornment imageSrc={"/Images/Icons/LOCK.svg"} />
            ),
          }}
        />
        <LoadingButton
          variant="contained"
          type="submit"
          sx={{ borderRadius: "50px" }}
          loading={loading}
        >
          Get Started - For Free!
        </LoadingButton>
      </Box>
    </>
  );
};

export default PersonalDetails;
