import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { useState } from "react";
import { useUpdateUserProfileMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";
import StyledSignupInputText from "../UiComponents/InputText/signupInputText";
import { StyledLoadingButton } from "../UiComponents/StyledButton";
import InputTextAdornment from "../common/InputTextAdornment";

const ConnectYourSocials = () => {
  const [alert, setAlert] = useState(false);
  const setAuthState = useAuthStore.setState;

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      youtube: "",
      twitter: "",
      instagram: "",
      facebook: "",
    },
    onSubmit: async () => {
      addSocials();
    },
  });

  const [addSocials, { loading, error }] = useUpdateUserProfileMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        onboardingActiveStep: state.onboardingActiveStep + 1,
        onboardingStepFinished: [...state.onboardingStepFinished, "ADD_SOCIAL"],
      }));
    },
    variables: {
      input: {
        addToUserOnBoardingStepFinished: "ADD_SOCIAL",
        socials: {
          facebook: values.facebook ?? "",
          instagram: values.instagram ?? "",
          twitter: values.twitter ?? "",
          youtube: values.youtube ?? "",
        },
      },
    },
  });

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 3, width: "100%" }}
      >
        <StyledSignupInputText
          name="instagram"
          value={values.instagram}
          placeholder="username"
          onChange={handleChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputTextAdornment
                text={"instagram.com/"}
                imageSrc={"/Images/OnBoarding/INSTAGRAM.svg"}
              />
            ),
          }}
        />
        <StyledSignupInputText
          name="facebook"
          value={values.facebook}
          placeholder="username"
          onChange={handleChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputTextAdornment
                text={"facebook.com/"}
                imageSrc={"/Images/OnBoarding/FACEBOOK.svg"}
              />
            ),
          }}
        />
        <StyledSignupInputText
          name="youtube"
          value={values.youtube}
          placeholder="username"
          onChange={handleChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputTextAdornment
                text={"youtube.com/"}
                imageSrc={"/Images/OnBoarding/YOUTUBE.svg"}
              />
            ),
          }}
        />
        <StyledSignupInputText
          name="twitter"
          value={values.twitter}
          placeholder="username"
          onChange={handleChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputTextAdornment
                text={"twitter.com/"}
                imageSrc={"/Images/OnBoarding/TWITTER.svg"}
              />
            ),
          }}
        />
        <StyledLoadingButton
          type="submit"
          variant="contained"
          loading={loading}
          onClick={() => handleSubmit()}
        >
          Next
        </StyledLoadingButton>
      </Box>
    </>
  );
};

export default ConnectYourSocials;
