import Box from "@mui/material/Box";
import { ReactNode } from "react";

const SignInLayout = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      width: "100vw",
      height: "100vh",
      backgroundImage: `url(/Images/SignUp/SIGNUP_BG.png)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: { xs: 3, sm: 4 },
        maxWidth: 549,
        width: "100%",
      }}
    >
      {children}
    </Box>
  </Box>
);

export default SignInLayout;
