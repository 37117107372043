import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useState } from "react";

import { PICK_YOUR_NICHE } from "../../constants/onboarding.constants";
import { useUpdateUserProfileMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";
import { StyledChip } from "../UiComponents/StyledChip";

const PickYourNiche = () => {
  const [alert, setAlert] = useState(false);
  const [selectedNiche, setSelectedNiche] = useState(["Others"]);
  const setAuthState = useAuthStore.setState;

  const handleSelectNicheClick = (niche: string) => {
    const niches = [...selectedNiche];

    if (niche.trim() !== "") {
      const index = niches.findIndex((x) => x === niche);

      if (index !== -1) {
        niches.splice(index, 1);
      } else {
        niches.push(niche);
      }

      setSelectedNiche(niches);
    }
  };

  const [addYourNiche, { loading, error }] = useUpdateUserProfileMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        onboardingActiveStep: state.onboardingActiveStep + 1,
        onboardingStepFinished: [...state.onboardingStepFinished, "ADD_NICHE"],
      }));
    },
    variables: {
      input: {
        pickYourNiche: selectedNiche ?? [],
        addToUserOnBoardingStepFinished: "ADD_NICHE",
      },
    },
  });

  const handleSubmit = () => {
    addYourNiche();
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Box display="flex" flexWrap="wrap" columnGap={2} rowGap={3}>
          {PICK_YOUR_NICHE.map((niche, index) => (
            <StyledChip
              key={index}
              label={niche.label}
              className={
                selectedNiche.find((x) => x === niche.label) ? "selected" : ""
              }
              icon={
                <Box component="img" src={`/Images/Icons/${niche.src}.svg`} />
              }
              onClick={() => handleSelectNicheClick(niche.label)}
            />
          ))}
        </Box>
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ borderRadius: "50px" }}
          onClick={() => handleSubmit()}
        >
          Next
        </LoadingButton>
      </Box>
    </>
  );
};

export default PickYourNiche;
