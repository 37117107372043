import Box from "@mui/material/Box";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/common.constants";
import { useGetMediaQueryUp } from "../../hooks/useGetMediaQuery";
import { cleanUpAuth } from "../../utils/auth";
import BottomNavigationBar from "./BottomNavigationBar";
import Header from "./Header";
import SideBar from "./SideBar";
import {
  MAIN_BODY_HEIGHT_DESKTOP,
  MAIN_BODY_HEIGHT_MOBILE,
} from "./index.constants";

const MainLayout = ({ children }: { children: ReactNode }) => {
  const isMdUp = useGetMediaQueryUp("md");
  const navigate = useNavigate();

  const handleLogout = () => {
    cleanUpAuth();
    navigate(ROUTES.SIGNIN);
  };

  return (
    <>
      {isMdUp && <Header />}
      <Box display="flex">
        {isMdUp && <SideBar handleLogout={handleLogout} />}
        <Box
          marginTop="20px"
          marginBottom="10px"
          px={isMdUp ? "20px" : 2}
          sx={{
            overflowY: "auto",
            height: isMdUp ? MAIN_BODY_HEIGHT_DESKTOP : MAIN_BODY_HEIGHT_MOBILE,
            width: "100%",
          }}
        >
          {children}
        </Box>
        {!isMdUp && <BottomNavigationBar />}
      </Box>
    </>
  );
};

export default MainLayout;
