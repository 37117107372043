import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";

import { useUpdateUserProfileMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";
import StyledInputText from "../UiComponents/InputText";

const LetsWriteABio = () => {
  const [alert, setAlert] = useState(false);
  const [bio, setBio] = useState("");
  const setAuthState = useAuthStore.setState;
  const handleSetBio = (value: string) => {
    setBio(value);
  };

  const [digitalProduct, { loading, error }] = useUpdateUserProfileMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        onboardingActiveStep: state.onboardingActiveStep + 1,
        onboardingStepFinished: [...state.onboardingStepFinished, "ADD_BIO"],
      }));
    },
    variables: {
      input: {
        shortBio: bio,
        addToUserOnBoardingStepFinished: "ADD_BIO",
      },
    },
  });

  const handleSubmit = () => {
    digitalProduct();
  };

  const handleSkip = () => {
    digitalProduct();
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}

      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}
      >
        <StyledInputText
          sx={{ display: "flex", width: "100%" }}
          onChange={(e) => handleSetBio(e.target.value)}
          placeholder="Celebrate life’s greatest moments, big & small."
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <LoadingButton
            loading={loading}
            variant="contained"
            sx={{ borderRadius: "50px" }}
            onClick={() => handleSubmit()}
          >
            Next
          </LoadingButton>
          <Button sx={{ borderRadius: "50px" }} onClick={handleSkip}>
            Skip
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LetsWriteABio;
