import _ from "lodash";
import { initialAuthStore, useAuthStore } from "../store/auth.store";

export const isUserLoggedIn = (): boolean => {
  const userAuth = useAuthStore.getState();

  if (
    userAuth.emailVerified &&
    userAuth.onboardingStatus === "completed" &&
    userAuth.onboardingStepFinished.length === 7
  ) {
    return true;
  }

  return false;
};

export function cleanUpAuth() {
  useAuthStore.setState(initialAuthStore);
}

export const isUserOnOnboarding = (): boolean => {
  const userAuth = useAuthStore.getState();
  if (userAuth.emailVerified && userAuth.onboardingStatus === "inProgress") {
    return true;
  }
  return false;
};

export const isUserInSignUp = (): boolean => {
  const userAuth = useAuthStore.getState();
  return !_.isEqual(userAuth, initialAuthStore) && !userAuth.emailVerified;
};

export const isUserInfoEmpty = (): boolean => {
  const userAuth = useAuthStore.getState();
  return _.isEqual(userAuth, initialAuthStore);
};
