import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const OnBoardingPageSkeleton = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Box
      display="flex"
      flexDirection="column"
      width={485}
      height={353}
      gap={3}
      pt={3}
    >
      <Skeleton
        variant="text"
        width={485}
        height={40}
        sx={{ borderRadius: 4 }}
      />
      <Skeleton
        variant="text"
        width={485}
        height={40}
        sx={{ borderRadius: 4 }}
      />
      <Skeleton
        variant="text"
        width={485}
        height={40}
        sx={{ borderRadius: 4 }}
      />
      <Skeleton
        variant="text"
        width={485}
        height={40}
        sx={{ borderRadius: 4 }}
      />
      <Skeleton
        variant="rounded"
        width={485}
        height={44}
        sx={{ borderRadius: "50px" }}
      />
    </Box>
  </Box>
);

export default OnBoardingPageSkeleton;
