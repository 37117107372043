import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { StyledBreadcrumbs } from "./StyleBreadcrumbs.styled";

export type BreadcrumbPath = {
  label: string;
  url?: string;
};

export type BreadcrumbPaths = {
  [path: string]: BreadcrumbPath[];
};

const paths: BreadcrumbPaths = {
  "/my-store/book-time-on-your-calendar-product": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],
  "/my-store/edit-booked-time-on-your-calendar-product/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Product" },
  ],
  "/my-store/add-new-link-product": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],
  "/my-store/edit-link-product/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Product" },
  ],
  "/my-store/add-collect-email-product": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],
  "/my-store/edit-collect-email-product/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Product" },
  ],
  "/my-store/add-new-sell-digital-product": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],
  "/my-store/edit-sell-digital-product/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Product" },
  ],
  "/my-store/edit-header": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Header" },
  ],

  "/my-store/add-webinar": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],

  "/my-store/edit-custom-product/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Product" },
  ],

  "/my-store/add-custom-product": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],

  "/my-store/edit-webinar/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Header" },
  ],

  "/my-store/add-affiliate-link-product": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],

  "/my-store/edit-affiliate-link-product/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Product" },
  ],

  "/my-store/add-tips-product": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],

  "/my-store/edit-tips-product/": [
    { label: "My Store", url: "/my-store" },
    { label: "Edit Product" },
  ],

  "/my-store/add-affiliate-product-listing": [
    { label: "My Store", url: "/my-store" },
    { label: "Add New Product" },
  ],

  "/appointments/overview/": [
    { label: "Appointments", url: "/appointments" },
    { label: "Overview" },
  ],

  "/audience/overview/": [
    { label: "Audience", url: "/audience" },
    { label: "Overview" },
  ],

  "/appointments": [{ label: "Appointments" }],

  "/audience": [{ label: "Audience" }],

  "/my-store": [{ label: "My Store" }],

  "/dashboard": [{ label: "Dashboard" }],

  "/analytics": [{ label: "Analytics", url: "/analytics" }],

  "/income": [{ label: "Income", url: "/income" }],
  "/income/order": [
    { label: "Income", url: "/income" },
    { label: "Order Details" },
  ],
  "/income/fulfill-order": [
    { label: "Income", url: "/income" },
    { label: "Fulfill Order" },
  ],
  "/preview": [{ label: "Preview", url: "/preview" }],
  "/settings": [{ label: "Settings", url: "/settings" }],
  "/referrals": [{ label: "Referrals", url: "/referrals" }],
};

export const Breadcrumbs = () => {
  const location = useLocation();

  const params = useParams();
  const filteredPath = useMemo(() => {
    if (paths[location.pathname]) return paths[location.pathname];

    if (!_.isEmpty(params)) {
      //check for query params present at last location and match path excluding query param
      const pathArr = location.pathname.split("/");
      pathArr.splice(-1, 1);

      const pathWithoutQueryParam = pathArr.join("/");

      if (pathWithoutQueryParam && paths[pathWithoutQueryParam])
        return paths[pathWithoutQueryParam];
    }

    const newPath = Object.keys(paths).filter((x) =>
      location.pathname.startsWith(x)
    )[0];

    if (newPath && paths[newPath]) return paths[newPath];

    return null;
  }, [location.pathname, params]);

  return (
    <>
      <StyledBreadcrumbs aria-label="breadcrumb">
        {filteredPath?.map((item, i) => (
          <Box key={i}>
            {filteredPath.length - 1 === i ? (
              <>
                <Typography variant="h6" color="text.primary">
                  {item.label}
                </Typography>
              </>
            ) : (
              <Link
                to={item?.url ?? ""}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Typography variant="h6">{item.label}</Typography>
              </Link>
            )}
          </Box>
        ))}
      </StyledBreadcrumbs>
    </>
  );
};

export default Breadcrumbs;
