import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthLinks from "../../../components/SignUp/SignUpLinks";
import SignupTitle from "../../../components/SignUp/SignupTitle";
import StyledInputText from "../../../components/UiComponents/InputText";
import InputTextAdornment from "../../../components/common/InputTextAdornment";
import { ROUTES } from "../../../constants/common.constants";
import { useUserLoginMutation } from "../../../graphql/generated/schema";
import {
  OnboardingStepsType,
  UserOnboardingStatus,
  useAuthStore,
} from "../../../store/auth.store";

const SignInPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refParam = searchParams.get("ref");
  const [alert, setAlert] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(true);
  const setAuthState = useAuthStore.setState;

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(6, "Password should be minimum 6 characters length")
      .required("Password is required"),
  });

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: validationSchema,
      onSubmit: async () => {
        loginUser();
      },
    });

  const [loginUser, { loading, error }] = useUserLoginMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: (data) => {
      const userData = data.userLogin.data;

      setAuthState({
        email: userData.email,
        emailVerified: userData.emailVerified,
        userName: userData.userName,
        fullName: userData.fullName,
        token: userData.token,
        onboardingStatus: userData.userOnboarding
          .status as UserOnboardingStatus,
        onboardingStepFinished: userData.userOnboarding
          .stepFinished as OnboardingStepsType,
        signUpActiveStep: !userData.emailVerified ? 2 : 0,
      });
      if (!userData.emailVerified) {
        navigate(ROUTES.SIGNUP, { replace: true });
      }

      if (userData.userOnboarding.status === "completed") {
        refParam
          ? navigate(refParam, { replace: true })
          : navigate(ROUTES.MY_STORE, { replace: true });
      } else {
        navigate(ROUTES.ONBOARDING, { replace: true });
      }
    },
    variables: {
      input: {
        email: values.email,
        password: values.password,
      },
    },
  });

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <SignupTitle title={"Sign In to Supr.Club"} />
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box display="flex" justifyContent="center">
            {alert && <Alert severity="error">{error?.message}</Alert>}
          </Box>
          <StyledInputText
            id="email"
            name="email"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            size="small"
            InputProps={{
              startAdornment: (
                <InputTextAdornment imageSrc={"/Images/Icons/MAIL.svg"} />
              ),
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              alignItems: "end",
            }}
          >
            <StyledInputText
              id="password"
              type={passwordVisible ? "password" : "text"}
              placeholder="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              size="small"
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputTextAdornment imageSrc={"/Images/Icons/LOCK.svg"} />
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ p: "0px !important" }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {<Box component={"img"} src="/Images/Icons/EYE.svg" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography>
              <Box
                component="a"
                href="/forget-password"
                sx={{
                  textDecoration: "none",
                  color: "secondary.main",
                }}
              >
                Forget Password?
              </Box>
            </Typography>
          </Box>
          <LoadingButton
            loading={loading}
            type="submit"
            sx={{ borderRadius: "50px" }}
            variant="contained"
          >
            Login
          </LoadingButton>
          <AuthLinks source="SIGNIN" />
        </Box>
      </Box>
    </>
  );
};

export default SignInPage;
