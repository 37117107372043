import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Suspense } from "react";
import "react-phone-input-2/lib/material.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./components/Loading";
import "./global.scss";
import AppRoutes from "./routes";
import { useAuthStore } from "./store/auth.store";
import theme from "./theme";
import { cleanUpAuth } from "./utils/auth";

function App() {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
  });

  const authLink = setContext((_, { headers }) => {
    const { token } = useAuthStore.getState();
    return {
      headers: {
        ...headers,
        ...(token ? { authorization: token } : {}),
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    //log out when api response return FORBIDDEN code
    if (
      graphQLErrors?.length &&
      graphQLErrors.some((e) => {
        const error = e as { code?: string };
        return error?.code === "FORBIDDEN" || error?.code === "UNAUTHORIZED";
      })
    ) {
      cleanUpAuth();
      window.location.href = "/signin";
    }
  });

  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <CssBaseline />
            <Router>
              <Suspense
                fallback={
                  <Loading
                    isLoading
                    loaderStyles={{ height: "100vh", width: "100vw" }}
                  />
                }
              >
                <AppRoutes />
              </Suspense>
            </Router>
          </LocalizationProvider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
