import Alert from "@mui/lab/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import ForgetPasswordEmailVerification from "../../../components/ForgetPassword/ForgetPasswordEmailVerification";
import SignupTitle from "../../../components/SignUp/SignupTitle";
import StyledInputText from "../../../components/UiComponents/InputText";
import InputTextAdornment from "../../../components/common/InputTextAdornment";
import { useSendForgetPasswordMailOtpMutation } from "../../../graphql/generated/schema";

const ForgetPassword = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [alert, setAlert] = useState(false);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter valid email")
      .required("Email is required"),
  });

  const { handleSubmit, handleChange, handleBlur, values, touched, errors } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: validationSchema,
      onSubmit: async () => {
        forgetPassword();
      },
    });

  const [forgetPassword, { loading, error }] =
    useSendForgetPasswordMailOtpMutation({
      onError: () => {
        setAlert(true);
      },
      onCompleted: () => {
        setIsEmailSent(true);
      },
      variables: {
        input: {
          email: values.email,
        },
      },
    });

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <SignupTitle title={"Forget Password!?"} />
        {!isEmailSent ? (
          <>
            {alert && <Alert severity="error">{error?.message}</Alert>}

            <StyledInputText
              name="email"
              placeholder="Enter Your Email ID"
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              value={values.email}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputTextAdornment imageSrc={"/Images/Icons/MAIL.svg"} />
                ),
              }}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ borderRadius: "50px" }}
              loading={loading}
            >
              Send Email
            </LoadingButton>
          </>
        ) : (
          <ForgetPasswordEmailVerification email={values.email} />
        )}
      </Box>
    </>
  );
};

export default ForgetPassword;
