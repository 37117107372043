import Box from "@mui/material/Box";
import ClaimYourLink from "../../../components/SignUp/ClaimYourLink";
import EmailVerification from "../../../components/SignUp/EmailVerification";
import PersonalDetails from "../../../components/SignUp/PersonalDetails";
import AuthLinks from "../../../components/SignUp/SignUpLinks";
import SignupTitle from "../../../components/SignUp/SignupTitle";
import { useAuthStore } from "../../../store/auth.store";

const SignUpPage = () => {
  const setAuthState = useAuthStore.setState;
  const activeStep = useAuthStore((state) => state.signUpActiveStep);
  const userName = useAuthStore((state) => state.userName);

  const handleChangeUserName = (value: string) => {
    setAuthState({ userName: value });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SignupTitle
          title={
            activeStep === 2 ? "Verify Your Email" : "Sign Up to Supr.Club"
          }
          subTitle={
            activeStep === 2
              ? "Please enter the 4 digit code that we send to your email address."
              : ""
          }
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4, pt: 3 }}>
          {activeStep === 0 && (
            <ClaimYourLink
              userName={userName}
              handleChangeUserName={handleChangeUserName}
            />
          )}

          {activeStep === 1 && (
            <PersonalDetails
              userName={userName}
              handleChangeUserName={handleChangeUserName}
            />
          )}

          {activeStep === 2 && <EmailVerification />}

          <AuthLinks activeStep={activeStep} />
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpPage;
