import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { StyledListItemButton } from "../index.styled";
import { ISidebarList } from "./types";

const SideBarList = (props: ISidebarList) => {
  const { image, isActive, text, handleClick } = props;
  return (
    <ListItem disablePadding sx={{ display: "flex", justifyContent: "center" }}>
      <StyledListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: isActive ? "neutral.400" : "",
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: 22,
            minWidth: 22,
          }}
        >
          <Box component="img" src={`/Images/Icons/${image}.svg`} />
        </ListItemIcon>
        <Typography color="secondary.main" variant="bodyLight">
          {text}
        </Typography>
      </StyledListItemButton>
    </ListItem>
  );
};

export default SideBarList;
