import BottomNavigation from "@mui/material/BottomNavigation";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import {
  ActiveNavigationTabOptionsEnum,
  useLayoutStore,
} from "../../../store/layout.store";
import { BOTTOM_NAVIGATION_HEIGHT } from "../index.constants";
import { StyledBottomNavigationAction } from "../index.styled";

const BottomNavigationBar = () => {
  const navigate = useNavigate();

  const setActiveNavigationTab = useLayoutStore(
    (state) => state.setActiveNavigationTab
  );

  const activeNavigationTab = useLayoutStore(
    (state) => state.activeNavigationTab
  );

  return (
    <>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          pt: 2,
          pb: 1.5,
          zIndex: 2,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={activeNavigationTab}
          sx={{ height: BOTTOM_NAVIGATION_HEIGHT, mx: 1.5 }}
          onChange={(event, newValue) => {
            setActiveNavigationTab(newValue as ActiveNavigationTabOptionsEnum);
            navigate(`/${newValue}`);
          }}
        >
          <StyledBottomNavigationAction
            value={ActiveNavigationTabOptionsEnum.PREVIEW}
            label={"Preview"}
            icon={<Box component="img" src={"/Images/Icons/eye2.svg"} />}
          />

          <StyledBottomNavigationAction
            label={"My Income"}
            value={ActiveNavigationTabOptionsEnum.INCOME}
            icon={<Box component="img" src={"/Images/Icons/Income.svg"} />}
          />
          <StyledBottomNavigationAction
            label={"My Store"}
            value={ActiveNavigationTabOptionsEnum.MY_STORE}
            icon={<Box component="img" src={"/Images/Icons/MyStore.svg"} />}
          />

          <StyledBottomNavigationAction
            label={"Analytics"}
            value={ActiveNavigationTabOptionsEnum.ANALYTICS}
            icon={<Box component="img" src={"/Images/Icons/Analytics.svg"} />}
          />

          <StyledBottomNavigationAction
            label={"Menu"}
            value={ActiveNavigationTabOptionsEnum.MENU}
            icon={<Box component="img" src={"/Images/Icons/addCircle.svg"} />}
          />
        </BottomNavigation>
      </Paper>
      <Box height={BOTTOM_NAVIGATION_HEIGHT + 28} />
    </>
  );
};

export default BottomNavigationBar;
