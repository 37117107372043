import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { MIN_SEARCH_CHARACTERS } from "../../constants/onboarding.constants";
import {
  useSearchUserNameQuery,
  useUpdateUserProfileMutation,
} from "../../graphql/generated/schema";
import useRebounceSearch from "../../hooks/useRebounce";
import { useAuthStore } from "../../store/auth.store";
import InputText from "../UiComponents/InputText";
import InputTextAdornment from "../common/InputTextAdornment";

interface IAutocompleteOption {
  fullname: string;
  username: string;
  id: string;
}

const AutocompleteOption = ({
  fullname,
  username,
}: {
  fullname: string;
  username: string;
}) => (
  <Box sx={{ display: "flex", gap: 2 }}>
    <Avatar src={"/Images/Icons/USER.svg"} sx={{ height: 34, width: 34 }} />
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        color="secondary.main"
        variant="subtitle1"
        fontSize={"14px"}
        fontWeight={800}
      >
        {fullname}
      </Typography>
      <Typography
        color="secondary.700"
        variant="subtitle1"
        fontSize={"12px"}
        fontWeight={500}
      >
        @{username}
      </Typography>
    </Box>
  </Box>
);

const SomeOneRefered = () => {
  const [alert, setAlert] = useState(false);
  const [reffered, setReffered] = useState<IAutocompleteOption>({
    fullname: "",
    username: "",
    id: "",
  });
  const [filterText, setFilterText] = useState("");
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    IAutocompleteOption[]
  >([]);
  const setAuthState = useAuthStore.setState;

  const { data, error } = useSearchUserNameQuery({
    skip: filterText.length < MIN_SEARCH_CHARACTERS,
    variables: {
      input: {
        filterText: filterText,
      },
    },
  });

  const [reference, { loading }] = useUpdateUserProfileMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        onboardingActiveStep: state.onboardingActiveStep + 1,
        onboardingStepFinished: [...state.onboardingStepFinished, "ADD_REFER"],
      }));
    },
    variables: {
      input: {
        referenceId: reffered.id,
        addToUserOnBoardingStepFinished: "ADD_REFER",
      },
    },
  });

  useEffect(() => {
    if (error) {
      setAlert(true);
    }
    if (data) {
      const options: IAutocompleteOption[] = data.searchUserName.data.map(
        (item) => ({
          fullname: item.fullName,
          username: item.userName,
          id: item._id,
        })
      );

      setAutocompleteOptions(options);
    } else {
      setAutocompleteOptions([]);
    }
  }, [data, error]);

  const { keyword, handleSearchKeywordChange } = useRebounceSearch(
    (searchValue) => {
      if (searchValue.length >= MIN_SEARCH_CHARACTERS) {
        handleChangeFilterText(searchValue);
      }
    }
  );

  const handleChangeReffered = (value: IAutocompleteOption) => {
    setReffered(value);
  };

  const handleChangeFilterText = (value: string) => {
    setFilterText(value);
  };

  const handlSubmit = () => {
    reference();
  };

  const handleSkip = () => {
    reference({
      variables: {
        input: {
          addToUserOnBoardingStepFinished: "ADD_REFER",
        },
      },
    });
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}
      <Box
        sx={{ display: "flex", width: "100%", flexDirection: "column", gap: 4 }}
      >
        <Autocomplete
          disableClearable
          options={autocompleteOptions}
          getOptionLabel={(option) => `${option.fullname} @${option.username}`}
          onChange={(_, newValue: IAutocompleteOption | null) =>
            handleChangeReffered(newValue as IAutocompleteOption)
          }
          renderOption={(props, option) => (
            <Box component={"li"} {...props}>
              <AutocompleteOption
                fullname={option.fullname}
                username={option.username}
              />
            </Box>
          )}
          renderInput={(params) => (
            <InputText
              placeholder="Creator @handle"
              {...params}
              fullWidth
              onChange={(e) => handleSearchKeywordChange(e.target.value)}
              InputProps={{
                ...params.InputProps,
                value: keyword,
                type: "search",
                startAdornment: (
                  <InputTextAdornment imageSrc={"/Images/Icons/USER.svg"} />
                ),
                endAdornment: "",
              }}
            />
          )}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <LoadingButton
            disabled={!reffered?.id.length}
            loading={loading}
            variant="contained"
            sx={{ borderRadius: "50px" }}
            onClick={() => handlSubmit()}
          >
            Next
          </LoadingButton>
          <Button sx={{ borderRadius: "50px" }} onClick={handleSkip}>
            Skip
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SomeOneRefered;
