export enum ROUTES {
  DEFAULT = "/",
  SIGNIN = "/signin",
  SIGNUP = "/signup",
  ONBOARDING = "/onboarding",
  ONBOARDING_SUCCESS = "/onboarding/success",
  ADD_NEW_LINK_PRODUCT = "/my-store/add-new-link-product",
  EDIT_HEADER = "/my-store/edit-header",
  FORGET_PASSWORD = "/forget-password",
  RESET_PASSWORD = "/reset-password",
  MY_STORE = "/my-store",
  INCOME = "/income",
  ANALYTICS = "/analytics",
  AUDIENCE = "/audience",
  APPOINTMENTS = "/appointments",
  REFERRALS = "/referrals",
  PREVIEW = "/preview",
  SETTINGS = "/settings",
  EDIT_LINK_PRODUCT = "/my-store/edit-link-product/:id",
  STORE_MAIN_PAGE = "/s/:username",
  ADD_NEW_EMAIL_PRODUCT = "/my-store/add-new-email-product",
  ADD_NEW_SELL_DIGITAL_PRODUCT = "/my-store/add-new-sell-digital-product",
  EDIT_SELL_DIGITAL_PRODUCT = "/my-store/edit-sell-digital-product/:id",
  ADD_COLLECT_EMAIL_PRODUCT = "/my-store/add-collect-email-product",
  EDIT_COLLECT_EMAIL_PRODUCT = "/my-store/edit-collect-email-product/:id",
  ADD_BOOK_TIME_ON_YOUR_CALENDAR = "/my-store/book-time-on-your-calendar-product",
  EDIT_BOOKED_TIME_ON_YOUR_CALENDAR = "/my-store/edit-booked-time-on-your-calendar-product/:id",
  ADD_WEBINAR = "/my-store/add-webinar",
  EDIT_WEBINAR = "/my-store/edit-webinar/:id",
  ADD_CUSTOM_PRODUCT = "/my-store/add-custom-product",
  EDIT_CUSTOM_PRODUCT = "/my-store/edit-custom-product/:id",
  ADD_AFFILIATE_LINK = "/my-store/add-affiliate-link-product",
  EDIT_AFFILIATE_LINK = "/my-store/edit-affiliate-link-product/:id",
  ADD_TIPS = "/my-store/add-tips-product",
  EDIT_TIPS = "/my-store/edit-tips-product/:id",
  ORDER = "income/order/:orderNo",
  Fulfill_ORDER = "income/fulfill-order/:orderNo",
  GET_ZOOM_AUTH_CODE = "/zoom-auth",
  ADD_AFFILIATE_PRODUCT_LISTING = "/my-store/add-affiliate-product-listing",
  APPOINTMENTS_OVERVIEW = "/appointments/overview/:bookingId",
  AUDIENCE_OVERVIEW = "/audience/overview/:id",
  MENU = "/menu",
}

export const Tabs = [
  { label: "Thumbnail", img: "Thumb" },
  { label: "Checkout Page ", img: "CardEditGrey" },
];

export const AffiliateProductListingTabs = [
  { label: "Product Preview", img: "Thumb" },
  { label: "Product List ", img: "Bag" },
];

export const ProductThumbnailPreviews = [
  {
    label: "Button",
    imgSrc: "/Images/Store/Products/SellDigitalProduct/ButtonBordered.svg",
  },
  {
    label: "Callout",
    imgSrc: "/Images/Store/Products/SellDigitalProduct/CallOutBordered.svg",
  },
  {
    label: "Card",
    imgSrc: "/Images/Store/Products/SellDigitalProduct/CardBordered.svg",
  },
];

export const AffiliatePreviews = [
  {
    label: "Column",
    imgSrc: "/Images/Store/Products/AffiliateProductList/ColumnPreview.svg",
  },
  {
    label: "Verticl",
    imgSrc: "/Images/Store/Products/AffiliateProductList/VerticalPreview.svg",
  },
];

export const ImportProductTypes = ["Shopify", "Amazon", "Flipkart"];

export const DaysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
