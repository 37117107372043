import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import SignupTitle from "../../../components/SignUp/SignupTitle";
import { StyledButton } from "../../../components/UiComponents/StyledButton";
import { ROUTES } from "../../../constants/common.constants";
import { useAuthStore } from "../../../store/auth.store";

const OnboardingSuccess = () => {
  const fullName = useAuthStore((state) => state.fullName) ?? "";
  const navigate = useNavigate();

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 3, width: "100%" }}
    >
      <SignupTitle
        source="ONBOARDING"
        title={`${fullName}, Your store is ready to access.`}
        subTitle={`Hi ${fullName}, Welcome to Supr.Club!Your Store is ready please click the button below to access your Store.`}
      />
      <StyledButton
        sx={{ borderRadius: "50px" }}
        variant="contained"
        onClick={() => navigate(ROUTES.MY_STORE)}
      >
        Visit Your Store
      </StyledButton>
    </Box>
  );
};

export default OnboardingSuccess;
