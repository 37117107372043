import { PaletteColorOptions, createTheme } from "@mui/material/styles";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: PaletteColor;
  }
  interface PaletteOptions {
    white?: PaletteColorOptions;
    black?: PaletteColorOptions;
    stroke?: PaletteColorOptions;
    neutral?: PaletteColorOptions;
  }

  interface PaletteColor {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  interface SimplePaletteColorOptions {
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }

  interface TypographyVariants {
    button: CSSProperties;
    subtitle: CSSProperties;
    captionLarge: CSSProperties;
    captionLargeSemiBold: CSSProperties;
    body: CSSProperties;
    bodySmall: CSSProperties;
    bodySmallBold: CSSProperties;
    bodyLight: CSSProperties;
    bodyLargeBold: CSSProperties;
    bodyBold: CSSProperties;
    bodyLarge: CSSProperties;
    bodyUltraLarge: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    button?: CSSProperties;
    subtitle?: CSSProperties;
    captionLarge?: CSSProperties;
    captionLargeSemiBold?: CSSProperties;
    body?: CSSProperties;
    bodyLargeBold?: CSSProperties;
    bodyLight?: CSSProperties;
    bodyBold?: CSSProperties;
    bodySmallBold?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    button: true;
    subtitle: true;
    captionLarge: true;
    captionLargeSemiBold: true;
    body: true;
    bodyLargeBold: true;
    bodyLight: true;
    bodyBold: true;
    bodySmallBold: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
    black: true;
    stroke: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    white: true;
    black: true;
    stroke: true;
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    white: true;
    black: true;
    stroke: true;
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    white: true;
    black: true;
  }
}

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#6927DA",
      900: "#783DDE",
      800: "#8752E1",
      700: "#9668E5",
      600: "#A57DE9",
      500: "#B493ED",
      400: "#C3A9F0",
      300: "#D2BEF4",
      200: "#E1D4F8",
      100: "#F0E9FB",
    },
    error: {
      main: "#EC2D30",
      700: "#F64C4C",
      600: "#EB6F70",
      500: "#F49898",
      400: "#FFCCD2",
      300: "#FFEBEE",
      200: "#FEF2F2",
      100: "#FFFBFB",
    },
    success: {
      main: "#0C9D61",
      700: "#47B881",
      600: "#6BC497",
      500: "#97D4B4",
      400: "#C0E5D1",
      300: "#E5F5EC",
      200: "#F2FAF6",
      100: "#FBFEFC",
    },
    secondary: {
      main: "#374151",
      900: "#4B5462",
      800: "#5F6774",
      700: "#737A85",
      600: "#878D97",
      500: "#9BA0A8",
      400: "#AFB3B9",
      300: "#C3C6CB",
      200: "#D7D9DC",
      100: "#EBECEE",
    },
    neutral: {
      main: "#1F1F1F",
      800: "#EAECF0",
      700: "#9E9E9E",
      600: "#CACACA",
      500: "#C4C4C4",
      400: "#F8F8F8",
      300: "#F7F7F7",
      200: "#F5F5F5",
      100: "#D1D1D1",
    },
    white: {
      main: "#fff",
    },
    black: {
      main: "#000",
      800: "#1B1B1B",
      700: "#AEAEAE",
    },
    stroke: {
      main: "#EEEEEE",
    },
    background: {
      paper: "#fff",
      default: "#FCFDFF",
    },
    warning: {
      main: "#FE9B0E",
      700: "#FFAD0D",
      600: "#FFC62B",
      500: "#FFDD82",
      400: "#FFEAB3",
      300: "#FFF7E1",
      200: "#FFF9EE",
      100: "#FFFDFA",
    },
    action: {
      disabled: "rgba(255, 255, 255,1)",
      disabledBackground: "rgba(105, 39, 218, 0.2)",
    },
    info: {
      main: "#3A70E2",
      700: "#3B82F6",
      600: "#4BA1FF",
      500: "#93C8FF",
      400: "#BDDDFF",
      300: "#E4F2FF",
      200: "#F1F8FF",
      100: "#F8FCFF",
    },
    text: {
      primary: "#374151",
      disabled: "#E5E7EB",
    },
  },
  typography: {
    fontFamily: "'Mulish', sans-serif",
    h1: {
      fontSize: "56px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    },
    h2: {
      fontSize: "49px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
    },
    h3: {
      fontSize: "48px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "normal",
    },
    h4: {
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "normal",
    },
    h5: {
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "normal",
    },
    h6: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "normal",
    },
    bodyLargeBold: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "24px",
    },
    bodyLight: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
    },
    bodyBold: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "20px",
    },
    body1: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px" /* 142.857% */,
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "18px" /* 142.857% */,
    },
    subtitle1: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px" /* 114.286% */,
    },
    subtitle2: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px" /* 114.286% */,
    },
    bodySmallBold: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "16px" /* 114.286% */,
    },
  },
});

export default theme;
