export const ONBOARDDING_STEP = [
  {
    title: "Connect Your Socials",
    subTitle:
      "By adding your social media usernames, we can create your digital products for you!",
    skipabble: false,
    nextStep: true,
  },
  {
    title: "Do You Already Have a Digital Product?",
    subTitle: "",
    skipabble: false,
    nextStep: true,
  },
  {
    title: "What’s Your Main Goal With Supr.club",
    subTitle: "",
    skipabble: false,
    nextStep: true,
  },
  {
    title: "Pick Your Niche",
    subTitle: "",
    skipabble: false,
    nextStep: true,
  },
  {
    title: "Let’s write a short bio",
    subTitle:
      "You’ll will able to edit this later, so no need to get it perfect right now.",
    skipabble: true,
    nextStep: true,
  },
  {
    title: "Did Someone Refer You?",
    subTitle:
      "Let us know who referred you to Supr.Club & we’ll send them a gift!",
    skipabble: true,
    nextStep: true,
  },
  {
    title: "Are you interested in brand deals?",
    subTitle: "Are you ineterested in brand deals?",
    skipabble: false,
    nextStep: false,
  },
  {
    title: "",
    subTitle: "",
    skipabble: false,
    nextStep: false,
  },
];

export const SOCIALS = [
  {
    name: "instagram",
    label: "instagram.com/",
    src: "/Images/OnBoarding/INSTAGRAM.svg",
  },
  {
    name: "facebook",
    label: "facebook.com/",
    src: "/Images/OnBoarding/FACEBOOK.svg",
  },
  {
    name: "youtube",
    label: "youtube.com/",
    src: "/Images/OnBoarding/YOUTUBE.svg",
  },
  {
    name: "twitter",
    label: "twitter.com/",
    src: "/Images/OnBoarding/TWITTER.svg",
  },
];

export const PICK_YOUR_NICHE = [
  { label: "Mindset", src: "Brain" },
  { label: "Business", src: "Business" },
  { label: "Spirituality", src: "Ichtus" },
  { label: "Finance", src: "GoogleFinance" },
  { label: "Fitness", src: "MuscleFlexing" },
  { label: "Dating", src: "Zoosk" },
  { label: "Career", src: "PersonalGrowth" },
  { label: "Social Media", src: "SocialNetwork" },
  { label: "Wellness", src: "YogaSkinType1" },
  { label: "Arts", src: "Easel" },
  { label: "Education", src: "StudentCenter" },
  { label: "UGC", src: "School" },
  { label: "Beauty", src: "Perfume" },
  { label: "Events", src: "Event" },
  { label: "Others", src: "AskQuestion" },
];

export const MAIN_GOAL = [
  { id: 1, label: "Just Browsing" },
  { id: 2, label: "Upgrade My Link-in-Bio" },
  { id: 3, label: "Build My Email List" },
  { id: 4, label: "Monetize My Audience" },
  { id: 5, label: "Other" },
];

export const MIN_SEARCH_CHARACTERS = 2;
