import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { StyledButton } from "../../../components/UiComponents/StyledButton";
import { useAuthStore } from "../../../store/auth.store";
import { DRAWER_WIDTH, HEADER_HEIGHT } from "../index.constants";
import Breadcrumbs from "./Breadcrumbs";

const Header = () => {
  const userName = useAuthStore((state) => state.userName);

  const copyTextToClipboard = async (url: string) => {
    if ("clipboard" in navigator) {
      toast.success("Copied to clipboard");
      return await navigator.clipboard.writeText(url);
    }
  };

  const handleCopyToClipboard = (url: string) => {
    copyTextToClipboard(url);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        top: "20px",
        marginTop: "20px",
        right: "20px",
        left: `${DRAWER_WIDTH + 20}px`,
        width: `calc(100vw - ${DRAWER_WIDTH + 20 + 20}px)`,
        borderRadius: 1.5,
        boxShadow: "0px 2px 4px 0px rgba(165, 163, 174, 0.30)",
      }}
      color="white"
    >
      <Toolbar
        sx={{
          padding: 1.5,
          justifyContent: "space-between",
          gap: 1.25,
          minHeight: `${HEADER_HEIGHT}px !important`,
        }}
      >
        <Breadcrumbs />
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="bodyLargeBold" color="primary.main">
            {`supr.club/${userName}`}
          </Typography>
          <StyledButton
            sx={{ minWidth: 132 }}
            variant="outlined"
            startIcon={<Box component="img" src="/Images/Icons/Copy.svg" />}
            size="small"
            onClick={() => handleCopyToClipboard(`supr.club/${userName}`)}
          >
            Copy
          </StyledButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
