import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "../constants/common.constants";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import OnBoarding from "../pages/Auth/OnboardingPage";
import OnboardingSuccess from "../pages/Auth/OnboardingPage/OnboardingSuccess";
import ResetPassword from "../pages/Auth/ResetPassword";
import SignUpPage from "../pages/Auth/SignUpPage";
import SignInPage from "../pages/Auth/SigninPage";
import RootRoutePage from "../pages/RootRoutePage";
import AuthGuard from "./RouteGuard/AuthGuard";
import GuestGuard from "./RouteGuard/GuestGuard";

const Analytics = lazy(() => import("../pages/Analytics"));
const Appointments = lazy(() => import("../pages/Appointments"));
const AppointmentOverview = lazy(() => import("../pages/ApppointmentOverview"));
const Audience = lazy(() => import("../pages/Audience"));
const AudienceOverview = lazy(() => import("../pages/AudienceOverview"));

const FulfillOrder = lazy(() => import("../pages/FulfillOrder"));
const Income = lazy(() => import("../pages/Income"));
const MyStore = lazy(() => import("../pages/MyStore"));
const AddNewAffiliateLinkProduct = lazy(
  () => import("../pages/MyStore/AddNewAffiliateLinkProduct")
);
const AddNewAffiliateProductListing = lazy(
  () => import("../pages/MyStore/AddNewAffiliateProductListing")
);
const BookTimeOnYourCalendar = lazy(
  () => import("../pages/MyStore/AddNewBookATimeCalendarProduct")
);
const CustomProduct = lazy(
  () => import("../pages/MyStore/AddNewCustomProduct")
);
const AddNewEmailProduct = lazy(
  () => import("../pages/MyStore/AddNewEmailProduct")
);
const AddNewLinkProduct = lazy(
  () => import("../pages/MyStore/AddNewLinkProduct")
);
const AddNewSellDigitalProduct = lazy(
  () => import("../pages/MyStore/AddNewSellDegitalProduct")
);
const TipsProduct = lazy(() => import("../pages/MyStore/AddNewTips"));
const Webinar = lazy(() => import("../pages/MyStore/AddNewWebinar"));
const EditHeader = lazy(() => import("../pages/MyStore/EditHeader"));
const Order = lazy(() => import("../pages/Order"));
const StoreMainPage = lazy(() => import("../pages/StoreMainPage"));
const Referrals = lazy(() => import("../pages/Referrals"));
const Preview = lazy(() => import("../pages/Preview"));
const Settings = lazy(() => import("../pages/Settings"));
const MobileMenu = lazy(() => import("../pages/MobileMenu"));
const AppRoutes = () => (
  <Routes>
    <Route path={ROUTES.DEFAULT} element={<RootRoutePage />} />
    {/* Unauthenticated Routes */}
    <Route element={<GuestGuard />}>
      <Route path={ROUTES.SIGNIN} element={<SignInPage />} />
      <Route path={ROUTES.SIGNUP} element={<SignUpPage />} />
      <Route path={ROUTES.ONBOARDING} element={<OnBoarding />} />
      <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.ONBOARDING_SUCCESS} element={<OnboardingSuccess />} />
    </Route>

    {/* Authenticated Routes */}
    <Route element={<AuthGuard />}>
      <Route path={ROUTES.MY_STORE} element={<MyStore />} />
      <Route path={ROUTES.MENU} element={<MobileMenu />} />

      <Route path={ROUTES.EDIT_HEADER} element={<EditHeader />} />
      <Route path={ROUTES.EDIT_LINK_PRODUCT} element={<AddNewLinkProduct />} />
      <Route
        path={ROUTES.ADD_NEW_LINK_PRODUCT}
        element={<AddNewLinkProduct />}
      />
      <Route
        path={ROUTES.ADD_COLLECT_EMAIL_PRODUCT}
        element={<AddNewEmailProduct />}
      />
      <Route
        path={ROUTES.EDIT_COLLECT_EMAIL_PRODUCT}
        element={<AddNewEmailProduct />}
      />
      <Route
        path={ROUTES.ADD_NEW_SELL_DIGITAL_PRODUCT}
        element={<AddNewSellDigitalProduct />}
      />
      <Route
        path={ROUTES.EDIT_SELL_DIGITAL_PRODUCT}
        element={<AddNewSellDigitalProduct />}
      />
      <Route
        path={ROUTES.ADD_BOOK_TIME_ON_YOUR_CALENDAR}
        element={<BookTimeOnYourCalendar />}
      />
      <Route
        path={ROUTES.EDIT_BOOKED_TIME_ON_YOUR_CALENDAR}
        element={<BookTimeOnYourCalendar />}
      />
      <Route path={ROUTES.ADD_WEBINAR} element={<Webinar />} />
      <Route path={ROUTES.EDIT_WEBINAR} element={<Webinar />} />
      <Route path={ROUTES.ADD_CUSTOM_PRODUCT} element={<CustomProduct />} />
      <Route path={ROUTES.EDIT_CUSTOM_PRODUCT} element={<CustomProduct />} />
      <Route
        path={ROUTES.ADD_AFFILIATE_LINK}
        element={<AddNewAffiliateLinkProduct />}
      />
      <Route
        path={ROUTES.EDIT_AFFILIATE_LINK}
        element={<AddNewAffiliateLinkProduct />}
      />
      <Route path={ROUTES.ADD_TIPS} element={<TipsProduct />} />
      <Route path={ROUTES.EDIT_TIPS} element={<TipsProduct />} />
      <Route path={ROUTES.INCOME} element={<Income />} />
      <Route path={ROUTES.ORDER} element={<Order />} />
      <Route path={ROUTES.Fulfill_ORDER} element={<FulfillOrder />} />
      <Route path={ROUTES.ANALYTICS} element={<Analytics />} />
      <Route path={ROUTES.APPOINTMENTS} element={<Appointments />} />
      <Route
        path={ROUTES.APPOINTMENTS_OVERVIEW}
        element={<AppointmentOverview />}
      />
      <Route
        path={ROUTES.ADD_AFFILIATE_PRODUCT_LISTING}
        element={<AddNewAffiliateProductListing />}
      />
      <Route path={ROUTES.AUDIENCE} element={<Audience />} />
      <Route path={ROUTES.AUDIENCE_OVERVIEW} element={<AudienceOverview />} />
      <Route path={ROUTES.REFERRALS} element={<Referrals />} />
      <Route path={ROUTES.PREVIEW} element={<Preview />} />
      <Route path={ROUTES.SETTINGS} element={<Settings />} />
    </Route>
    <Route path={ROUTES.STORE_MAIN_PAGE} element={<StoreMainPage />} />

    {/* Wildcard Routes */}
    <Route path="*" element={<h1>Page not found!</h1>} />
  </Routes>
);

export default AppRoutes;
