import { Navigate } from "react-router-dom";
import { ROUTES } from "../../constants/common.constants";
import {
  isUserInSignUp,
  isUserInfoEmpty,
  isUserLoggedIn,
  isUserOnOnboarding,
} from "../../utils/auth";

const RootRoutePage = () => {
  //userInfo completely empty, user needs to log in
  if (isUserInfoEmpty()) {
    return <Navigate to={ROUTES.SIGNIN} replace />;
  }

  //userInfo exists, and userInfo indicates user is logged in
  if (isUserLoggedIn()) {
    return <Navigate to={ROUTES.MY_STORE} replace />;
  }

  //userInfo exists, and userInfo indicates user is in between onboarding process
  if (isUserOnOnboarding()) {
    return <Navigate to={ROUTES.ONBOARDING} replace />;
  }

  //userInfo exists, but emailVerified is false, indicating that user is between signup process
  if (isUserInSignUp()) {
    return <Navigate to={ROUTES.SIGNUP} replace />;
  }
};

export default RootRoutePage;
