import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";
import { MAIN_GOAL } from "../../constants/onboarding.constants";
import { useUpdateUserProfileMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";

const YourMainGoal = () => {
  const [alert, setAlert] = useState(false);
  const [mainGoal, setMainGoal] = useState("Just Browsing");
  const handleChangeMainGoal = (value: string) => {
    setMainGoal(value);
  };

  const setAuthState = useAuthStore.setState;

  const [addGoal, { loading, error }] = useUpdateUserProfileMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        onboardingActiveStep: state.onboardingActiveStep + 1,
        onboardingStepFinished: [...state.onboardingStepFinished, "ADD_GOAL"],
      }));
    },
    variables: {
      input: {
        addGoal: mainGoal,
        addToUserOnBoardingStepFinished: "ADD_GOAL",
      },
    },
  });
  const handleSubmit = () => {
    addGoal();
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}

      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 4 }}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={mainGoal}
            onChange={(e) => handleChangeMainGoal(e.target.value)}
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          >
            {MAIN_GOAL.map((goal, index) => (
              <FormControlLabel
                key={index}
                sx={{
                  border: "2px solid",
                  borderColor: "primary.800",
                  borderRadius: "50px",
                  mr: "0px !important",
                }}
                value={goal.label}
                control={<Radio />}
                label={goal.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ borderRadius: "50px" }}
          onClick={() => handleSubmit()}
        >
          Next
        </LoadingButton>
      </Box>
    </>
  );
};

export default YourMainGoal;
