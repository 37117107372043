import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

export const StyledChip = styled(Chip)(({ theme }) => ({
  padding: "6px 12px 6px 10px",
  gap: 6,
  backgroundColor: "rgba(232, 218, 255, 0.25)",
  borderRadius: theme.spacing(2),
  "& .MuiChip-label": {
    padding: 0,
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
  },
  "& .MuiChip-icon": {
    margin: 0,
  },
  "&.selected, &.selected:hover": {
    backgroundColor: theme.palette.primary[800],

    ".MuiChip-label": {
      color: "white",
    },
  },
  "&:hover": {
    backgroundColor: "rgba(232, 218, 255, 0.25)",
  },
}));
