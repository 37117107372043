import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useFindUserNameMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";
import StyledInputText from "../UiComponents/InputText";
import InputTextAdornment from "../common/InputTextAdornment";

export type SignUpStepsPropsType = {
  userName: string;
  handleChangeUserName: (value: string) => void;
};

const ClaimYourLink = (props: SignUpStepsPropsType) => {
  const { handleChangeUserName, userName } = props;

  const setAuthState = useAuthStore.setState;
  const [alert, setAlert] = useState(false);

  const [findUserName, { loading, error }] = useFindUserNameMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        signUpActiveStep: state.signUpActiveStep + 1,
      }));
    },
    variables: {
      input: {
        userName,
      },
    },
  });

  const handleSubmit = () => {
    findUserName();
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}
      <Box
        component={"form"}
        sx={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Typography variant="h6">Claim your Link in Bio!</Typography>
          <StyledInputText
            placeholder="username"
            value={userName}
            onChange={(e) => handleChangeUserName(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputTextAdornment
                  imageSrc={"/Images/FEVICON.png"}
                  text={"supr.club/"}
                />
              ),
            }}
          />
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          sx={{ borderRadius: "50px", textDecoration: "none" }}
          disabled={userName.length === 0 || userName.indexOf(" ") > -1}
          onClick={handleSubmit}
          loading={loading}
        >
          Next
        </LoadingButton>
      </Box>
    </>
  );
};

export default ClaimYourLink;
