export const DRAWER_WIDTH = 232;
export const HEADER_HEIGHT = 62;
export const BOTTOM_NAVIGATION_HEIGHT = 40;
export const DEVICE_RESOLUTION = { W: 328, H: 692 };
export const FINAL_DEVICE_RESOLUTION_WIDTH = 450;
//header height plus 20 for marginTop of header plus 20 for marginTop of main body's div plus 10 for marginBottom of main body's div
export const MAIN_BODY_HEIGHT_DESKTOP = `calc(100vh - ${
  HEADER_HEIGHT + 20 + 20 + 10
}px)`;

//bottom navigation height plus 20 for marginTop of main body's div plus 10 for marginBottom of main body's div
export const MAIN_BODY_HEIGHT_MOBILE = `calc(100vh - ${
  BOTTOM_NAVIGATION_HEIGHT + 20 + 10
}px)`;

//-50px for tabs height
export const MY_STORE_TAB_CONTENT_HEIGHT_DESKTOP = `calc(${MAIN_BODY_HEIGHT_DESKTOP} - 50px)`;
export const MY_STORE_TAB_CONTENT_HEIGHT_MOBILE = `calc(${MAIN_BODY_HEIGHT_MOBILE} - 50px)`;
export const BOTTOM_NAVIGATION_ITEMS = [
  { icon: "eye2", label: "Preview" },
  { icon: "Income", label: "My Income" },
  { icon: "MyStore", label: "My Store" },
  { icon: "Analytics", label: "Analytics" },
  { icon: "addCircle", label: "More" },
];
