import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";

import { useUpdateUserProfileMutation } from "../../graphql/generated/schema";
import { useAuthStore } from "../../store/auth.store";

const DigitalProduct = () => {
  const [alert, setAlert] = useState(false);
  const [haveDigitalProduct, setHaveDigitalProduct] = useState("no");
  const handleChangeDigitalProduct = (value: string) => {
    setHaveDigitalProduct(value);
  };

  const setAuthState = useAuthStore.setState;

  const [digitalProduct, { loading, error }] = useUpdateUserProfileMutation({
    onError: () => {
      setAlert(true);
    },
    onCompleted: () => {
      setAuthState((state) => ({
        onboardingActiveStep: state.onboardingActiveStep + 1,
        onboardingStepFinished: [
          ...state.onboardingStepFinished,
          "ADD_DIGITAL_PRODUCT",
        ],
      }));
    },
    variables: {
      input: {
        digitalProduct: haveDigitalProduct === "no" ? false : true,
        addToUserOnBoardingStepFinished: "ADD_DIGITAL_PRODUCT",
      },
    },
  });

  const handlSubmit = () => {
    digitalProduct();
  };

  return (
    <>
      {alert && <Alert severity="error">{error?.message}</Alert>}

      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 4 }}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={haveDigitalProduct}
            onChange={(e) => handleChangeDigitalProduct(e.target.value)}
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          >
            <FormControlLabel
              sx={{
                border: "2px solid",
                borderColor: "primary.800",
                borderRadius: "50px",
                mr: "0px !important",
              }}
              value="yes"
              control={<Radio />}
              label="Yes - I already have a product!"
            />
            <FormControlLabel
              sx={{
                border: "2px solid",
                borderColor: "primary.800",
                borderRadius: "50px",
                mr: "0px !important",
                "MuiSvgIcon-root": {
                  color: "red",
                },
              }}
              value="no"
              control={<Radio />}
              label="No - I need help creating a product!"
            />
          </RadioGroup>
        </FormControl>
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ borderRadius: "50px" }}
          onClick={() => handlSubmit()}
        >
          Next
        </LoadingButton>
      </Box>
    </>
  );
};

export default DigitalProduct;
